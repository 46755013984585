import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'
import React from 'react'
import { grayscale } from 'src/styles/colors'
import * as S from './style'

import textPT from '../../assets/data/translate/uma-vida-financeira-inteligente/text-pt.json'

import DoubleVideo from '../../components/DoubleVideoUS/index'

const UmaVidaFinanceiraInteligente = React.forwardRef((props: { setIsOpen: Function }, ref: React.Ref<HTMLDivElement>) => {
  const { title, CTA }: ISlideScrollSections = textPT
  const { setIsOpen }: { setIsOpen: Function } = props

  return (
    <S.ModelsSectionBackground
      key='TESTE 3'
      className='colored d-flex align-items-end align-items-md-center'
      ref={ref}
    >
      <>
        <DoubleVideo
          urlMp4='https://central-imagens.bancointer.com.br/gif/teste-webm-video/arquivo.webm'
          urlWeb='https://central-imagens.bancointer.com.br/gif/teste-webm-video/arquivo.webm'
          videoSrc='https://central-imagens.bancointer.com.br/gif/teste-webm-video/arquivo.webm'
        />

        <S.ContainerVideo className='container'>
          <div className='row'>
            <S.TextContainer className='col-12'>
              <S.TitleExtraLarge
                as='h2' className='fw-400 text-grayscale--500'
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <div>
                <S.CTA
                  as='button'
                  className='text-grayscale--500'
                  onClick={() => setIsOpen(true)}
                  aria-label={CTA.text}
                >
                  {CTA.text}
                  <ArrowRight height={24} width={24} color={grayscale[500]} className='ml-2' />
                </S.CTA>
              </div>
            </S.TextContainer>
          </div>
        </S.ContainerVideo>
      </>
    </S.ModelsSectionBackground>
  )
})

export default UmaVidaFinanceiraInteligente
