import React, { useLayoutEffect, useState } from 'react'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { WIDTH_MD } from 'src/styles/breakpoints'

import useWidth from 'src/hooks/window/useWidth'
import textPT from '../../assets/data/translate/um-super-app/text-pt.json'
import * as S from './style'

const UmSuperApp = () => {
  const { title, description, tagDescription, CTA }: ISlideScrollSections = textPT
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth(300)
  const [ isMobile, setIsMobile ] = useState(true)

  const imageMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/herobanner-cel-mobile/image.webp'
  const imageDesktop = 'https://central-imagens.bancointer.com.br/images-without-small-versions/herobanner-cel-web/image.webp'

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  return (
    <S.ModelsSectionBackground
      key='TESTE'
      className='d-flex align-items-start align-items-md-center'
    >
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 col-md-6 mt-4 mt-md-0 text-center'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/hero-intercel-texto/image.webp'
              arrayNumbers={ [ 241.5, 335, 433, 433, 578 ] }
              arrayNumbersHeight={ [ 174.29, 262, 300, 300, 415 ] }
              altDescription='Promoção Inter Celular'
              role='heading'
              aria-level='1'
            />
            <S.TitlePromotion
              className='fs-16 lh-20 fs-md-20 lh-md-24 fs-lg-28 lh-lg-34 fs-xl-32 lh-xl-38 text-white font-citrina'
              dangerouslySetInnerHTML={{ __html: title }}
              aria-label={title}
            />
            <S.TagChipVirtual
              className='font-citrina text-white fs-16 lh-20 fs-md-20 lh-md-24 fs-lg-28 lh-lg-34 fs-xl-32 lh-xl-38 d-flex align-items-center justify-content-center'
            >
              {tagDescription}
            </S.TagChipVirtual>
            <div className='d-flex align-items-center justify-content-center flex-column'>
              <S.CTA
                to={CTA.link}
                aria-label={CTA.text}
                title={CTA.text}
                className='font-citrina fs-18 lh-22 fs-lg-26 lh-lg-32 fw-600 fs-xl-36 lh-xl-43 text-white d-flex align-items-center justify-content-center'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    element_action: 'click button',
                    element_name: CTA.text,
                    section_name: description,
                    redirect_url: CTA.link,
                  })
                }}
              >
                {CTA.text}
              </S.CTA>
            </div>
          </div>
          <S.ImageBottom className='col-12 col-md-6'>
            <ImageWebp
              pathSrc={isMobile ? imageMobile : imageDesktop}
              arrayNumbers={ [ 258.75, 292, 363.42, 363.42, 479 ] }
              arrayNumbersHeight={ [ 317.91, 360, 508.46, 508.46, 658 ] }
              altDescription='Duas mulheres olhando para um celular, curtindo a promoção do Inter'
              className='ml-2 ml-md-0'
            />
          </S.ImageBottom>
        </div>
      </div>
    </S.ModelsSectionBackground>
  )
}

export default UmSuperApp
