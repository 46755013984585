import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'
import React from 'react'
import textPT from '../../assets/data/translate/sua-vida-globa-e-inter/text-pt.json'
import backgrounds from '../../assets/img/backgrounds'
import * as S from './style'

const SuaVidaGlobalEInter = () => {
  const { title, CTA }: ISlideScrollSections = textPT

  return (
    <S.ModelsSectionBackground
      key='TESTE 8'
      className='colored d-flex align-items-end align-items-md-center'
      mobileBackgroundImage={backgrounds.backgroundHomeSection8.mobile}
      backgroundImage={backgrounds.backgroundHomeSection8.desktop}
      modelName='TESTE 8'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-7 offset-md-6 offset-xl-5'>
            <div className='ml-md-5 pl-xl-5'>
              <S.TitleExtraLarge
                as='h2' className='fw-400 f-sora text-white'
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <div>
                <S.CTA to={CTA.link} className='text-white'>
                  {CTA.text}
                  <ArrowRight height={24} width={24} color='#FFFF' className='ml-2' />
                </S.CTA>
              </div>
            </div>
          </div>
        </div>
      </div>
    </S.ModelsSectionBackground>
  )
}

export default SuaVidaGlobalEInter
