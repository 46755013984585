import { Link } from 'gatsby'
import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

import { brand, orange } from 'src/styles/colors'
import { IModelsSectionBackground } from '../../types'

export const ModelsSectionBackground = styled('div')<IModelsSectionBackground>`
  height: calc(100vh - 64px);
  background: ${orange[500]};
  scroll-snap-align: start;
  scroll-snap-stop: always;

  @media ${device.desktopLG} {
    height: calc(100vh - 73px);
  }
`

export const TitlePromotion = styled.p`
  margin-top: 10px;
`

export const TagChipVirtual = styled.div`
  background: rgba(0, 0, 0, 0.1);
  border-radius: 65px;
  margin: 15px auto;
  height: 32px;
  max-width: 247px;

  @media ${device.tablet} {
    max-width: 285px;
    height: 37px;
  }
  @media ${device.desktopLG} {
    max-width: 389px;
    height: 52px;
  }
  @media ${device.desktopXL} {
    height: 68px;
    min-width: 509px;
  }
`

export const CTA = styled(Link)`
  background: ${brand.claryOrange};
  min-width: 297px;
  height: 51px;
  padding: 15px;
  outline: inherit;
  border-radius: 10px;

  &:focus {
    border: 2px solid;
  }
  &:hover {
    opacity: 0.7;
  }

  @media ${device.desktopLG} {
    margin-top: 5px;
    min-width: 389px;
    height: 67px;
    border-radius: 15px;
  }
  @media ${device.desktopXL} {
    min-width: 509px;
  }
`

export const ImageBottom = styled.div`
  margin-top: 26px;
  align-items: center;
  display: flex;
  justify-content: center;

  @media ${device.tablet} {
    margin-top: 0;
  }
`
